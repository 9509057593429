import React from "react";
import "./App.css";
import Home from "./Home";
import Game from "./game/Game";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Communication from "./communication/Communication";

const App: React.FC = () => {

    return (
        <Router>
            <Routes>
                {/* <Route path="/" element={<Home />} /> */}
                {/* <Route path="/game" element={<Game />} /> */}
                <Route path="/lol" element={<Communication />} />
            </Routes>
        </Router>
    );
};

export default App;


// const socket = io('https://kymspace.co.uk', { transports: ['websocket', 'polling'], path: '/socket.io' });
import { useRef, useEffect, useState } from 'react';

import io from 'socket.io-client';
import './Communication.css'

interface OfferData {
  offer: RTCSessionDescriptionInit;
  to: string;
}

interface AnswerData {
  answer: RTCSessionDescriptionInit;
  to: string;
}

interface IceCandidateData {
  candidate: RTCIceCandidateInit;
  to: string;
}

interface WatcherData {
  broadcasterId: string;
}

function Communication() {

  const [sourceType, setSourceType] = useState<'screen' | 'camera' | 'videoDevice'>('screen');
  const [resolution, setResolution] = useState<'4k' | '2k' | '1080p' | '720p' | '480p'>('1080p');


  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');


  const [isPreviewing, setIsPreviewing] = useState<boolean>(false);
  const previewStreamRef = useRef<MediaStream | null>(null);

  const [permissionsGranted, setPermissionsGranted] = useState(false);

  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(1); // Volume ranges from 0 to 1


  const videoRef = useRef<HTMLVideoElement>(null);
  const pcRef = useRef<{ [key: string]: RTCPeerConnection }>({});
  const socketRef = useRef<ReturnType<typeof io> | null>(null);
  const isStreamingRef = useRef<boolean>(false);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const streamRef = useRef<MediaStream | null>(null);
  const [showPlayButton, setShowPlayButton] = useState<boolean>(false);
  const [isWatching, setWatching] = useState<boolean>(false);
  const isScreenShareSupported = !!navigator.mediaDevices.getDisplayMedia;


  // Toggle Play/Pause
const togglePlayPause = () => {
  if (!videoRef.current) return;
  
  if (videoRef.current.paused || videoRef.current.ended) {
    videoRef.current.play();
    setIsPlaying(true);
  } else {
    videoRef.current.pause();
    setIsPlaying(false);
  }
};

// Toggle Mute/Unmute
const toggleMute = () => {
  if (!videoRef.current) return;
  
  videoRef.current.muted = !videoRef.current.muted;
  setIsMuted(videoRef.current.muted);
};

// Handle Volume Change
const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const newVolume = parseFloat(event.target.value);
  if (videoRef.current) {
    videoRef.current.volume = newVolume;
    videoRef.current.muted = newVolume === 0;
    setVolume(newVolume);
    setIsMuted(newVolume === 0);
  }
};

// Toggle Fullscreen
const toggleFullscreen = () => {
  if (!videoRef.current) return;
  
  if (!document.fullscreenElement) {
    videoRef.current.requestFullscreen().catch(err => {
      console.error(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
    });
  } else {
    document.exitFullscreen();
  }
};

const getVideoDevices = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoInputs = devices.filter((device) => device.kind === 'videoinput');
  setVideoDevices(videoInputs);
  if (videoInputs.length > 0) {
    setSelectedDeviceId(videoInputs[0].deviceId);
  }
}

const startPreview = async () => {
  console.log('startPreview called');
  let stream: MediaStream | null = null;

  try {
    const constraints: MediaStreamConstraints = getMediaConstraints();

    if (sourceType === 'screen') {
      console.log(`Attempting to preview ${sourceType} capture.`);
      // Screen Capture
      stream = await navigator.mediaDevices.getDisplayMedia(constraints);
    } else if (sourceType === 'camera') {
      console.log('Attempting to preview default camera stream.');
      // Default Camera
      stream = await navigator.mediaDevices.getUserMedia(constraints);
    } else if (sourceType === 'videoDevice' && selectedDeviceId) {
      console.log(`Attempting to preview video device stream for device ID: ${selectedDeviceId}`);
      let videoConstraints: MediaTrackConstraints = {};

      if (typeof constraints.video === 'object' && constraints.video !== null) {
        videoConstraints = constraints.video;
      }

      constraints.video = {
        ...videoConstraints,
        deviceId: { exact: selectedDeviceId },
      };

      stream = await navigator.mediaDevices.getUserMedia(constraints);
    }

    if (stream) {
      console.log('Preview stream obtained successfully:', stream);

      // Stop any existing preview stream
      if (previewStreamRef.current) {
        console.log('Stopping existing preview stream before starting new preview.');
        previewStreamRef.current.getTracks().forEach((track) => track.stop());
      }

      previewStreamRef.current = stream;
      setIsPreviewing(true);

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.muted = true; // Mute to prevent echo
        await videoRef.current.play();
        console.log('Preview video is playing.');
      }


      // Handle stop sharing
      stream.getTracks().forEach((track) => {
        track.addEventListener('ended', () => {
          console.log('Preview track ended, stopping preview.');
          stopPreview();
        });
      });
    }
  } catch (err) {
    console.error(`Error starting preview for ${sourceType}:`, err);
  }
};

  
  
  const stopPreview = () => {
    console.log('stopPreview called');
    setIsPreviewing(false);
    if (previewStreamRef.current) {
      previewStreamRef.current.getTracks().forEach((track) => track.stop());
      previewStreamRef.current = null;
    }
  
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };
  

  const getMediaConstraints = (): MediaStreamConstraints => {
    let width: number;
    let height: number;
    let frameRate: number;
  
    switch (resolution) {
      case '4k':
        width = 3840;
        height = 2160;
        frameRate = 60;
        break;
      case '2k':
        width = 2560;
        height = 1440;
        frameRate = 60;
        break;
      case '1080p':
        width = 1920;
        height = 1080;
        frameRate = 60;
        break;
      case '720p':
        width = 1280;
        height = 720;
        frameRate = 30;
        break;
      case '480p':
        width = 854;
        height = 480;
        frameRate = 30;
        break;
      default:
        width = 1920;
        height = 1080;
        frameRate = 30;
    }
  
    const videoConstraints: MediaTrackConstraints = {
      width: { ideal: width },
      height: { ideal: height },
      frameRate: { ideal: frameRate, max: frameRate },
    };
  
    return {
      video: videoConstraints,
      audio: true,
    };
  };

  const handleSourceTypeChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSource = event.target.value as 'screen' | 'camera' | 'videoDevice';
    setSourceType(selectedSource);
  
    if (selectedSource === 'videoDevice') {
      try {
        // Request media access to get device labels
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
        
        // Enumerate devices after permission is granted
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoInputs = devices.filter((device) => device.kind === 'videoinput');
        setVideoDevices(videoInputs);
        if (videoInputs.length > 0) {
          setSelectedDeviceId(videoInputs[0].deviceId);
        }
        
        // Stop the stream immediately as we only needed access to get labels
        stream.getTracks().forEach((track) => track.stop());
      } catch (err) {
        console.error('Error accessing video devices:', err);
        alert('Unable to access video devices. Please check your permissions.');
      }
    }
  };
  

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!isStreaming) return;
  
      switch (event.key) {
        case ' ':
          event.preventDefault();
          togglePlayPause();
          break;
        case 'm':
        case 'M':
          toggleMute();
          break;
        case 'f':
        case 'F':
          toggleFullscreen();
          break;
        default:
          break;
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isStreaming, isPlaying, isMuted, volume]);
  

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;
  
    // Update play/pause state
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleEnded = () => setIsPlaying(false);
  
    // Update mute state
    const handleVolumeChangeEvent = () => {
      setIsMuted(video.muted);
      setVolume(video.volume);
    };
  
    video.addEventListener('play', handlePlay);
    video.addEventListener('pause', handlePause);
    video.addEventListener('ended', handleEnded);
    video.addEventListener('volumechange', handleVolumeChangeEvent);
  
    // Cleanup event listeners on unmount
    return () => {
      video.removeEventListener('play', handlePlay);
      video.removeEventListener('pause', handlePause);
      video.removeEventListener('ended', handleEnded);
      video.removeEventListener('volumechange', handleVolumeChangeEvent);
    };
  }, []);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoInputs = devices.filter((device) => device.kind === 'videoinput');
        setVideoDevices(videoInputs);
        if (videoInputs.length > 0) {
          setSelectedDeviceId(videoInputs[0].deviceId);
        }
      } catch (err) {
        console.error('Error enumerating devices:', err);
      }
    };
  
    fetchDevices();
  }, []);

  useEffect(() => {
    // Initialize Socket.IO
    // const socket = io('http://localhost:5000'); 
    const socket = io('https://kymspace.co.uk', { transports: ['websocket', 'polling'], path: '/socket.io' });


    socketRef.current = socket;

    socket.on('connect', () => {
      console.log('Connected to signaling server:', socket.id);
      socket.emit('join-room'); // Notify the server that we've joined
    });

    // Handle when a broadcaster starts streaming
    socket.on('broadcaster', (broadcasterId: string) => {
      if (isStreamingRef.current) {
        // If we are the broadcaster, no need to do anything
        return;
      }
      console.log('Received broadcaster info:', broadcasterId);
      socket.emit('watcher', { broadcasterId });
    });

    // Handle when a new viewer joins (only relevant if we are the broadcaster)
    socket.on('watcher', (viewerId: string) => {
      if (!isStreamingRef.current) {
        // If we are not the broadcaster, ignore
        return;
      }
      console.log('New viewer joined:', viewerId);
      createPeerConnection(viewerId);
      const peerConnection = pcRef.current[viewerId];

      // Add tracks to the peer connection
      streamRef.current?.getTracks().forEach((track) => {
        const sender = peerConnection.addTrack(track, streamRef.current!);

          // Adjust sender parameters for 720p 40fps // -----------------4K 120fps
          if (track.kind === 'video') {
            const parameters = sender.getParameters();
            if (!parameters.encodings || parameters.encodings.length === 0) {
              parameters.encodings = [{}];
            }
            // Set maxBitrate based on resolution
            let maxBitrate: number;
            let maxFramerate: number;

            switch (resolution) {
              case '4k':
                maxBitrate = 25000000; // 25 Mbps
                maxFramerate = 60;
                break;
              case '2k':
                maxBitrate = 8000000; // 8 Mbps
                maxFramerate = 60;
                break;
              case '1080p':
                maxBitrate = 5000000; // 5 Mbps
                maxFramerate = 60;
                break;
              case '720p':
                maxBitrate = 2500000; // 2.5 Mbps
                maxFramerate = 30;
                break;
              case '480p':
                maxBitrate = 1000000; // 1 Mbps
                maxFramerate = 30;
                break;
              default:
                maxBitrate = 5000000;
                maxFramerate = 30;
            }

            parameters.encodings[0].maxBitrate = maxBitrate;
            parameters.encodings[0].maxFramerate = maxFramerate;
            sender.setParameters(parameters);
          }
        });

      peerConnection
        .createOffer()
        .then((offer) => peerConnection.setLocalDescription(offer))
        .then(() => {
          socket.emit('offer', {
            offer: peerConnection.localDescription!,
            to: viewerId,
          });
        });
    });

    socket.on('offer', (data: { offer: RTCSessionDescriptionInit; from: string }) => {
      const peerConnection = createPeerConnection(data.from);

      peerConnection
        .setRemoteDescription(new RTCSessionDescription(data.offer))
        .then(() => peerConnection.createAnswer())
        .then((answer) => peerConnection.setLocalDescription(answer))
        .then(() => {
          socket.emit('answer', {
            answer: peerConnection.localDescription!,
            to: data.from,
          });
        });
    });

    socket.on('answer', (data: { answer: RTCSessionDescriptionInit; from: string }) => {
      const peerConnection = pcRef.current[data.from];
      peerConnection.setRemoteDescription(new RTCSessionDescription(data.answer));
    });

    socket.on('ice-candidate', (data: { candidate: RTCIceCandidateInit; from: string }) => {
      const peerConnection = pcRef.current[data.from];
      peerConnection.addIceCandidate(new RTCIceCandidate(data.candidate));
    });

    socket.on('disconnectPeer', (peerId: string) => {
      console.log('Peer disconnected:', peerId);
      const peerConnection = pcRef.current[peerId];
      if (peerConnection) {
        peerConnection.close();
        delete pcRef.current[peerId];
      }
      setWatching(false);
    });

    // Clean up on unmount
    return () => {
      socket.disconnect();
      Object.values(pcRef.current).forEach((pc) => pc.close());

      // Stop preview stream if any
      if (previewStreamRef.current) {
        previewStreamRef.current.getTracks().forEach((track) => track.stop());
        previewStreamRef.current = null;
      }
    };
  }, []);

  const createPeerConnection = (peerId: string) => {
    let peerConnection = pcRef.current[peerId];
    if (peerConnection) return peerConnection;

    const configuration: RTCConfiguration = {
      iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
    };

    peerConnection = new RTCPeerConnection(configuration);
    pcRef.current[peerId] = peerConnection;

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        socketRef.current?.emit('ice-candidate', {
          candidate: event.candidate,
          to: peerId,
        });
      }
    };

    peerConnection.ontrack = (event) => {
      if (videoRef.current && !isStreamingRef.current) {
        console.log('Received remote stream');
        videoRef.current.srcObject = event.streams[0];

        // Show play button overlay
        setShowPlayButton(true);
      }
    };

    return peerConnection;
  };

  const startStream = async () => {
    console.log('startStream called'); // Debugging log
  
    // Stop any existing preview stream
    if (previewStreamRef.current) {
      console.log('Stopping existing preview stream before starting new stream.');
      previewStreamRef.current.getTracks().forEach((track) => track.stop());
      previewStreamRef.current = null;
      setIsPreviewing(false);
    }
  
    let stream: MediaStream | null = null;
  
    try {
      const constraints: MediaStreamConstraints = getMediaConstraints();
  
      if (sourceType === 'screen') {
        console.log(`Attempting to start ${sourceType} capture.`);
        // Screen Capture
        stream = await navigator.mediaDevices.getDisplayMedia(constraints);
      } else if (sourceType === 'camera') {
        console.log('Attempting to start default camera stream.');
        // Default Camera
        stream = await navigator.mediaDevices.getUserMedia(constraints);
      } else if (sourceType === 'videoDevice' && selectedDeviceId) {
        console.log(`Attempting to start video device stream for device ID: ${selectedDeviceId}`);
        let videoConstraints: MediaTrackConstraints = {};
  
        if (typeof constraints.video === 'object' && constraints.video !== null) {
          videoConstraints = constraints.video;
        }
  
        constraints.video = {
          ...videoConstraints,
          deviceId: { exact: selectedDeviceId },
        };
  
        stream = await navigator.mediaDevices.getUserMedia(constraints);
      }
  
      if (stream) {
        console.log('Stream obtained successfully:', stream);
        isStreamingRef.current = true;
        setIsStreaming(true);
        streamRef.current = stream;
  
        setIsPlaying(true);
        setIsMuted(false);
        setVolume(1);
  
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.muted = true; // Mute to prevent echo
          await videoRef.current.play();
          console.log('Local video is playing.');
        }
  
        socketRef.current?.emit('broadcaster');
  
        // Re-enumerate devices to get updated labels
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoInputs = devices.filter((device) => device.kind === 'videoinput');
        setVideoDevices(videoInputs);
        if (videoInputs.length > 0) {
          setSelectedDeviceId(videoInputs[0].deviceId);
        }
  
        // Handle stop sharing
        stream.getTracks().forEach((track) => {
          track.addEventListener('ended', () => {
            console.log('Track ended, stopping stream.');
            stopStream();
          });
        });
      }
    } catch (err) {
      console.error('Error starting stream:', err);
    }
  };

  const stopStream = () => {

    setIsPlaying(false);
    setIsMuted(false);
    setVolume(1);

    isStreamingRef.current = false;
    setIsStreaming(false);
    streamRef.current?.getTracks().forEach((track) => track.stop());
    streamRef.current = null;

    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }

    socketRef.current?.emit('stop');

    // Close all peer connections
    Object.values(pcRef.current).forEach((pc) => pc.close());
    pcRef.current = {};
  };

  const handlePlayButtonClick = () => {
    if (videoRef.current) {
      videoRef.current
        .play()
        .then(() => {
          console.log('Video is playing');
          setShowPlayButton(false); // Hide the play button
        })
        .catch((error) => {
          console.error('Error attempting to play video:', error);
        });

        setWatching(true);
    }
  };

  const [darkMode, setDarkMode] = useState(false);

  // Toggle between dark and white modes
  const toggleMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    // <div className={`app-container ${darkMode ? 'dark-mode' : 'white-mode'}`}>
    //   <button onClick={toggleMode} className="toggle-button">
    //     {darkMode ? 'Switch to White Mode' : 'Switch to Dark Mode'}
    //   </button>

    //   <div className="container">
    //     <h1>{darkMode ? 'Dark Mode Active' : 'White Mode Active'}</h1>

    //   </div>
    // </div>
    <div
    style={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '100vh',
      overflow: 'visible',
      backgroundColor: '#121212',
      color: '#fff',
      fontFamily: 'Arial, sans-serif',
    }}
  >
    <h1 style={{ marginTop: '20px' }}>Screen Sharing Test</h1>

    {/* Source Type Selection */}
    <div style={{ marginBottom: '20px' }}>
      <label style={{ marginRight: '10px' }}>Select Source:</label>
      <select value={sourceType} onChange={handleSourceTypeChange}>
        <option value="screen">Screen Capture</option>
        <option value="camera">Camera Stream</option>
        <option value="videoDevice">Video Capture Device</option>
      </select>
    </div>

    {/* Resolution Selection */}
    <div style={{ marginBottom: '20px' }}>
      <label style={{ marginRight: '10px' }}>Select Resolution:</label>
      <select value={resolution} onChange={(e) => setResolution(e.target.value as any)}>
        <option value="4k">4K</option>
        <option value="2k">2K</option>
        <option value="1080p">1080p</option>
        <option value="720p">720p</option>
        <option value="480p">480p</option>
      </select>
    </div>


    {/* Video Device Selection */}
    {sourceType === 'videoDevice' && (
      <div style={{ marginBottom: '20px' }}>
        <label style={{ marginRight: '10px' }}>Select Video Device:</label>
        <select value={selectedDeviceId} onChange={(e) => setSelectedDeviceId(e.target.value)}>
          {videoDevices.map((device) => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label || `Device ${device.deviceId}`}
            </option>
          ))}
        </select>
      </div>
    )}
    {/* Preview and Stop Preview Buttons */}
    {!isStreaming && !isPreviewing && (
      <button
        onClick={startPreview}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          cursor: 'pointer',
          borderRadius: '5px',
          backgroundColor: '#1DB954',
          color: 'white',
          border: 'none',
          marginBottom: '20px',
        }}
      >
        Preview
      </button>
    )}
    {isPreviewing && (
      <button
        onClick={stopPreview}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          cursor: 'pointer',
          borderRadius: '5px',
          backgroundColor: '#FF0000',
          color: 'white',
          border: 'none',
          marginBottom: '20px',
        }}
      >
        Stop Preview
      </button>
    )}

    {/* Start Stream Button */}
    {!isStreaming && (
      <button
        onClick={startStream}
        disabled={isPreviewing}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          cursor: isPreviewing ? 'not-allowed' : 'pointer',
          borderRadius: '5px',
          backgroundColor: isPreviewing ? '#ccc' : '#1DB954',
          color: 'white',
          border: 'none',
          marginBottom: '20px',
        }}
      >
        Start Stream
      </button>
    )}
    {isStreaming && (
      <button
        onClick={stopStream}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          cursor: 'pointer',
          borderRadius: '5px',
          backgroundColor: '#FF0000',
          color: 'white',
          border: 'none',
          marginBottom: '20px',
        }}
      >
        Stop Stream
      </button>
    )}

    {/* Video Display Area */}
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        overflow: 'auto',
        width: '100%',
      }}
    >
      <video
        ref={videoRef}
        playsInline
        controls={false} // We'll add custom controls
        style={{
          width: '70%',
          maxWidth: '1200px',
          margin: '0 10px',
          borderRadius: '10px',
          backgroundColor: '#000',
        }}
      ></video>
      {showPlayButton && (
        <div
          style={{
            position: 'absolute',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            width: '70%',
            maxWidth: '1200px',
            height: '70%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '10px',
          }}
          onClick={handlePlayButtonClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="64"
            viewBox="0 0 24 24"
            width="64"
            fill="#fff"
          >
            <path d="M8 5v14l11-7z" />
          </svg>
          <p style={{ fontSize: '18px' }}>Click to Start the Stream</p>
        </div>
      )}
    </div>

    {/* Custom Control Bar */}
    {(isStreaming || isWatching) && (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '10px',
          gap: '20px',
          backgroundColor: '#1e1e1e',
          padding: '10px 20px',
          borderRadius: '10px',
        }}
      >
        {/* Play/Pause Button */}
        <button
          onClick={togglePlayPause}
          style={{
            padding: '10px',
            fontSize: '16px',
            cursor: 'pointer',
            borderRadius: '50%',
            backgroundColor: isPlaying ? '#FF0000' : '#1DB954',
            color: 'white',
            border: 'none',
            width: '50px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          title={isPlaying ? 'Pause' : 'Play'}
        >
          {isPlaying ? '❚❚' : '►'}
        </button>

        {/* Mute/Unmute Button */}
        <button
          onClick={toggleMute}
          style={{
            padding: '10px',
            fontSize: '16px',
            cursor: 'pointer',
            borderRadius: '50%',
            backgroundColor: isMuted ? 'grey' : 'blue',
            color: 'white',
            border: 'none',
            width: '50px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          title={isMuted ? 'Unmute' : 'Mute'}
        >
          {isMuted ? '🔇' : '🔊'}
        </button>

        {/* Volume Slider */}
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
          style={{ cursor: 'pointer', width: '150px' }}
          title="Volume"
        />

        {/* Fullscreen Button */}
        <button
          onClick={toggleFullscreen}
          style={{
            padding: '10px',
            fontSize: '16px',
            cursor: 'pointer',
            borderRadius: '50%',
            backgroundColor: '#1DB954',
            color: 'white',
            border: 'none',
            width: '50px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          title="Toggle Fullscreen"
        >
          ⛶
        </button>
      </div>
    )}
  </div>

  );
}

export default Communication;
